<template>
  <div class="orderSettle">
    <Header
      v-if="!mealRules.newUI"
      backType="white"
      :backEvent="true"
      @backEvent="back"
      :back="!fromXBH"
    ></Header>
    <Header
      v-else
      backType="white"
      :backEvent="true"
      @backEvent="back"
      :back="!fromXBH"
    >
      <span slot="headText" style="font-size: 0.17rem">支付信息</span>
    </Header>
    <div class="orderSettle-container">
      <div v-if="!mealRules.newUI" class="publicHead1">
        <p>支付信息</p>
        <Tips :tips="tips" />
      </div>
      <AttentionTips
        v-if="tips.length > 0 && mealRules.newUI"
        :tips="tips"
        :newUI="mealRules.newUI ? true : false"
      />
      <div class="normalInputRow">
        <MInput
          title="消费总金额"
          type="number"
          :labelBlack="mealRules.newUI ? true : false"
          pla="请输入消费总金额"
          must="true"
          :disabled="orderDetail.forbidUpdateAmount"
          v-model="input.originAmount"
        ></MInput>
      </div>
      <template>
        <div
          class="normalInputRow"
          v-if="
            !['crossevent', 'pfizer'].includes(event.tenant_code) ||
            fromXBH ||
            event.tenant_name == '会引擎-huarun'
          "
        >
          <MInput
            title="酒水金额"
            type="number"
            :labelBlack="mealRules.newUI ? true : false"
            pla="请输入酒水金额"
            must="true"
            v-if="!disabled"
            :disabled="disabled"
            v-model="input.WineAmount"
          ></MInput>
          <MInput
            title="酒水金额"
            type="text"
            :labelBlack="mealRules.newUI ? true : false"
            pla="请输入酒水金额"
            must="true"
            v-else
            :disabled="disabled"
            v-model="input.WineAmount"
          ></MInput>
        </div>
        <div class="normalInputRow">
          <MInput
            title="实际人数"
            type="tel"
            :labelBlack="mealRules.newUI ? true : false"
            pla="请输入实际人数"
            must="true"
            v-model="input.qty"
          ></MInput>
        </div>
      </template>
      <div class="invoice_title" v-if="mealRules.newUI">
        水单
        <span> (请务必清晰拍照，提交后不能再修改) </span>
      </div>
      <div class="paymentInvoice">
        <viewer
          :images="waterFilesList"
          class="imgContainer"
          :class="mealRules.newUI ? 'imgContainer_new' : ''"
        >
          <template v-for="list in waterFilesList">
            <div :key="list.url">
              <img :src="list.url" alt />
              <Svgs
                v-if="mealRules.newUI"
                color="#F37600"
                classes="paymentDelete"
                name="iconjiaobiaoshanchu"
                @click.native="deleteInvoice(list)"
              ></Svgs>
              <Svgs
                v-else
                color="#FF484D"
                classes="paymentDelete"
                name="iconshanchu1"
                @click.native="deleteInvoice(list)"
              ></Svgs>
            </div>
          </template>
          <div class="add_div" v-if="mealRules.newUI" @click="upLoadInvoice">
            <Svgs classes="paymentAdd" color="#B9BABB" name="iconhao"></Svgs>
          </div>
        </viewer>
      </div>
    </div>
    <div v-if="mealRules.newUI && hideshow" class="paymentBtn_new">
      <Btn btnClass="paymentSubmit" @callBack="submitForm">
        <span slot="btnContent">提交</span>
      </Btn>
    </div>
    <div v-if="!mealRules.newUI" class="paymentBtnRow2">
      <Btn btnClass="paymentUpload" @callBack="upLoadInvoice">
        <span slot="btnContent">
          <Svgs
            classes="paymentUploadImg"
            color="#ffffff"
            name="icontupian"
          ></Svgs
          >上传水单
        </span>
      </Btn>
      <Btn btnClass="paymentSubmit" @callBack="submitForm">
        <span slot="btnContent">提交</span>
      </Btn>
    </div>
    <MessageBox
      ref="remove"
      type="tips"
      messageTxt="确定删除图片？"
      @onOk="remove"
    ></MessageBox>
    <MessageBox
      ref="shoperr"
      type="tips"
      okTxt="联系客服"
      messageTxt="餐厅暂无企业支付，请联系客服协助支付"
      @onOk="ihtml.telCall('01056304858')"
    ></MessageBox>
    <BtnGroup ref="btnGroup" :btnList="btnList"></BtnGroup>
    <BrowserLoader ref="browserLoader" @close="meiTuanCancel" />
    <smart-pay
      :host="alipayHost"
      :orderData="voucherInput"
      :requireList="requireList"
      :onComplete="completeAlipay"
      :onClose="closeAlipay"
      ref="aliPay"
    ></smart-pay>
    <van-dialog
      v-model="imgShow"
    >
      <img class="dialogImg" src="../../assets/img/xms_img_lilly.png" />
    </van-dialog>
  </div>
</template>

<script>
import { miceService } from "@/service/miceService.js";
import mealSYT from "@/components/Meal/mealSYT.js";
import electrinicFence from "@/utils/electrinicFence.js";
import mealDdianPing from "@/components/Meal/mealDdianPing.js";
import { mapGetters, mapMutations } from "vuex";
import { smartPay } from "smart-alipay";
import myAlipay from "@/components/Pay/myAlipay.js";
import {
  orderSettle,
  orderSettleNor,
  orderSettleHr,
} from "@/config/orderSettle.js";
import BtnGroup from "./BtnGroup";
import { UploadPlugOCR, UploadPlug } from "smart-filer-vue";
import { SmartStorage } from "smart-core-util";
import { indicator } from "smart-indicator";
import { getCookie } from "tiny-cookie";
import { Dialog } from "vant";
const uploadInstance = new UploadPlugOCR({
  host: process.env.VUE_APP_GATEWAY,
});
const uploadInstance2 = new UploadPlug({
  host: process.env.VUE_APP_GATEWAY,
});
export default {
  name: "OrderSettle",
  mixins: [mealSYT, mealDdianPing, myAlipay, electrinicFence],
  components: {
    smartPay,
    BtnGroup,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      fromXBH: false,
      input: {
        originAmount: "",
        qty: "",
        Catalog: "水单",
      },
      disabled: false,
      list: {},
      waterFilesList: [],
      btnList: [
        {
          txt: "拍照",
          callback: this.graph,
        },
        {
          txt: "从相册选取",
          callback: this.album,
        },
      ],
      first: false,
      requireList: [],
      msgValue: "",
      address: {},
      tips: [],
      hideshow: true,
      docmHeight: 0,
      showHeight: 0,
      imygeoLocation: {
        nl: '0',
        el: '0',
      },
      imgShow: false,
    };
  },
  computed: {
    ...mapGetters([
      "event",
      "orderDetail",
      "memsg",
      "mealRules",
      "currentPosition",
    ]),
    validator: {
      get: function () {
        var tenant_code = this.event.tenant_code;
        return orderSettle[tenant_code];
      },
    },
  },
  async mounted() {
    await this.getLocationFailed(true)
    if (
      this.orderDetail.sourceCode == 'XiaoMiShu'
    ) {
      this.imgShow = true
    }
    if (SmartStorage.get("isH5") && SmartStorage.get("type") == "imeal") {
      let res = await this.getMealRules();
      this["MEAL_RULES"](res);
    }
    this.getTip();
    this.fromXBH = SmartStorage.get("isH5");
    if (
      ["novartis"].includes(this.event.tenant_code) &&
      this.orderDetail.itemName == "午餐"
    ) {
      this.input.WineAmount = "午餐禁止酒水";
      this.disabled = true;
    }
    let temp = (this.orderDetail.itsItemData || { payProfile: "" })
      .payProfile || { PayModes: [] };
    let payMode =
      typeof temp.PayModes == "object" ? temp.PayModes[0] : temp.PayModes;
    if (payMode == 10) {
      this.input.originAmount = this.orderDetail.extAmount || undefined;
      this.input.qty = this.orderDetail.realQty || undefined;
    }
    let platForm = navigator.userAgent.toUpperCase();
    let isAndroid =
      platForm.indexOf("ANDROID") > -1 || platForm.indexOf("LINUX") > -1;
    if (isAndroid) {
      window.addEventListener("resize", () => {
        if (!this.isResize) {
          this.docmHeight =
            window.document.documentElement.clientHeight || window.innerHeight;
          this.isResize = true;
        }
        //实时屏幕高度
        this.showHeight =
          window.document.documentElement.clientHeight || window.innerHeight;
      });
    }
    this.getWaterFiles();
  },
  watch: {
    showHeight: function () {
      if (this.docmHeight !== this.showHeight) {
        this.hideshow = true;
      } else {
        this.hideshow = false;
      }
    },
  },
  methods: {
    ...mapMutations([
      "ORDER_DETAIL",
      "XMS_PAY_RESULT",
      "EXPENSE_ID",
      "MEAL_RULES",
    ]),
    meiTuanCancel() {
      this.$router.push({ path: "/orderList" });
    },
    async getMealRules() {
      let params = {
        collection: "cfg-imeal-mealrules",
        useCaching: false,
        cachingKey: "",
        filter: {
          tenant_id: getCookie("tenant_id") || getCookie("tenantId"),
        },
      };
      return new Promise((resolve, reject) => {
        miceService
          .getsettings(params)
          .then((res) => {
            if (res && res.success && res.content) {
              resolve(res.content);
            } else {
              resolve({});
            }
          })
          .catch((err) => {
            reject(false);
          });
      });
    },
    getTip() {
      let tip = [];
      switch (this.event.tenant_code) {
        case "novartis":
          tip = ["特别提示：实际用餐人数应小于等于会议实际签到人数"];
          break;
        case "dscl":
          tip = ["支付信息（*为必填）"];
          break;
      }
      // 疾控提示
      if (
        ["线上培训会"].includes(this.event.typeDictTxt) &&
        this.event.tenant_name == "会引擎-疾控"
      ) {
        tip.push("每场会议餐标预算400元");
      }
      if (
        ["主题培训会"].includes(this.event.typeDictTxt) &&
        this.event.tenant_name == "会引擎-疾控"
      ) {
        tip.push("每场会议餐标预算5000元");
      }
      if (this.event.tenant_name == "会引擎-huarun") {
        tip.push(
          "酒水金额不可超过消费总金额的35%",
          "酒水不应包含茅台、五粮液等高端品牌"
        );
      }
      if (["会引擎-灵北"].includes(this.event.tenant_name)) {
        tip = ["如果水单为商户手写，请将水单原件同其他报销材料一起提交"];
      }
      this.tips = tip;
    },
    deleteInvoice(list) {
      this.list = list;
      this.$refs.remove.openPop();
    },
    remove() {
      indicator.open();
      uploadInstance.deleteAsync({
        fileItem: {
          fileKey: this.list.fileKey,
        },
        bucketAlias: "SmartEvent",
        callback: () => {
          indicator.close();
          this.getWaterFiles();
        },
      });
    },
    getWaterFiles() {
      uploadInstance.queryFile({
        filterData: {
          filterData: {
            relatedId: this.orderDetail.itemId,
            catalog: "水单",
          },
        },
        callback: (data) => {
          this.waterFilesList = data.content;
          if (this.first) {
            setTimeout(() => {
              document.querySelector(".orderSettle-container").scrollTo({
                top: 100000,
                behavior: "smooth",
              });
            }, 200);
          }
          this.first = true;
        },
      });
    },
    async upLoadInvoice() {
      //uploadFileData 零时配置，全部租户商线后取消
      if (this.mealRules.uploadFileData) {
        this.btnList = [
          {
            txt: "拍照",
            callback: this.graph,
          },
        ];
      }
      // if (window.flutter_inappwebview) {
      //   let uploadFileData = {
      //     hideCamera: false,
      //     hideGallery: this.mealRules.uploadFileData ? true : false,
      //     hidePdfAndOfd: true,
      //   };
      //   this.iJsBridge.uploadFile(uploadFileData, this.mappingAsync);
      //   return;
      // }
      if (["会引擎-灵北"].includes(this.event.tenant_name)) {
        this.btnList = [
          {
            txt: "拍照",
            callback: this.graph,
          },
        ];
      }
      this.$refs.btnGroup.openPop();
    },
    graph() {
      this.$refs.btnGroup.openPop();
      let _this = this;
      uploadInstance2.cameraFile({
        callback: (data) => {
          indicator.open();
          uploadInstance.addAsync({
            fileList: data,
            ossKey: "",
            bucketAlias: "SmartEvent",
            geoLocation: {
              latitude: _this.imygeoLocation.nl,
              longitude: _this.imygeoLocation.el,
            },
            callback: (result) => {
              indicator.close();
              _this.mappingAsync(result);
            },
          });
        },
      });
    },
    album() {
      this.$refs.btnGroup.openPop();
      let _this = this;
      uploadInstance.localUpload({
        callback: (data) => {
          indicator.open();
          uploadInstance.addAsync({
            fileList: data,
            ossKey: "",
            bucketAlias: "SmartEvent",
            geoLocation: {
              latitude: _this.imygeoLocation.nl,
              longitude: _this.imygeoLocation.el,
            },
            callback: (result) => {
              indicator.close();
              _this.mappingAsync(result);
            },
          });
        },
      });
    },
    mappingAsync(result) {
      let mappingData = {
        processId:
          this.orderDetail.proposalId ||
          SmartStorage.get("proposalId") ||
          SmartStorage.get("eventData").proposalId,
          relatedId: this.orderDetail.itemId,
          relatedType: "settle",
          catalog: "水单",
      };
      if (this.mealRules.uploadFileData) {
        let auditData = {
          province: this.address.province,
          city: this.address.city,
          address: this.address.address,
        };
        mappingData.auditData = JSON.stringify(auditData);
      }
      uploadInstance.mappingAsync({
        fileKeys: [result[0].content.fileKey],
        mappingData: mappingData,
        callback: () => {
          this.getWaterFiles();
        },
      });
    },
    async submitForm() {
      if (this.waterFilesList.length)
        this.input.hasFiles = this.waterFilesList.length ? true : false;
      if (
        this.event.tenant_name == "会引擎-huarun" &&
        this.input.WineAmount / this.input.originAmount > 35 / 100
      ) {
        this.toast("酒水金额不可超过消费总金额的35%");
        return;
      }
      let validateResult = true;
      if (
        ["novartis"].includes(this.event.tenant_code) &&
        this.orderDetail.itemName == "午餐"
      ) {
        validateResult = this.validate(this.input, orderSettleNor);
      } else if (["会引擎-huarun"].includes(this.event.tenant_name)) {
        validateResult = this.validate(this.input, orderSettleHr);
      } else {
        validateResult = this.validate(this.input, this.validator);
      }
      if (!validateResult) return;
      if (
        ["novartis"].includes(this.event.tenant_code) &&
        this.orderDetail.itemName == "晚餐" &&
        this.input.WineAmount / this.input.originAmount > 25 / 100
      ) {
        this.toast("外出用餐晚餐酒水金额不可超总金额的25%");
        return;
      }
      if (
        ["novartis"].includes(this.event.tenant_code) &&
        this.orderDetail.itemName == "午餐"
      ) {
        delete this.input.WineAmount;
      }
      this.input.itemId = this.orderDetail.itemId;
      if (["novartis"].includes(this.event.tenant_code)) {
        Dialog.confirm({
            title: "提示",
            message: "请确认您上传的水单为最终结算单！非最终结算单如“点菜单”、“预结单”、“预打单”等将被视为违反该要求并被记录。",
        })
        .then(async () => {
          if (
            this.orderDetail.sourceCode != "MeiTuan"
          ) {
            await this.getLocationFailed();
            this.submint();
          }else{
            this.submint();
          }
        })
        .catch(() => {
            console.log(0);
        });
        // this.$refs.submitForm.openPop();
        return;
      }
      if (this.orderDetail.sourceCode == "Amap") {
        await this.SearchShopDetail({
          shopId: this.orderDetail.itsItemData.shopId,
        });
      }
        await this.getLocationFailed();
        this.submint();
      // if (
      //   this.orderDetail.sourceCode != "MeiTuan"
      // ) {
      //   await this.getLocationFailed();
      //   this.submint();
      // } else {
      //   this.submint();
      // }
    },
    isWx() {
      var ua = navigator.userAgent.toLowerCase();
      var isWeixin = ua.indexOf("micromessenger") != -1;
      if (isWeixin) {
        return true;
      } else {
        return false;
      }
    },
    async submint() {
      if (this.mealRules && this.mealRules.alipay) {
        let path = "/alipayAuthorize";
        this.isWx()
          ? (path = `/alipayAuthorize?authorize=true&sessionId=${SmartStorage.get(
              "sessionId"
            )}&sid=${this.mealRules.sid}&userId=${
              this.memsg.userId
            }&authMobile=${SmartStorage.get(
              "authMobile"
            )}&rnd=${new Date().getTime()}`)
          : "";
        let resA = await this.CheckUserAuth();
        if (!resA.content.isUserAuth || !resA.content.isMemberAuth) {
          this.$router.push({
            path: path,
          });
          return;
        }
        let res3 = await this.queryElementsPromise();
        if (!res3.success) {
          return;
        }
        let res4 = await this.submitSettle();
        if (!res4.success) {
          return;
        }
        this["ORDER_DETAIL"]({
          ...this.orderDetail,
          orderAmount: this.input.originAmount,
          qty: this.input.qty,
          alipayCertificate: res3.content,
        });
        this["EXPENSE_ID"](res4.content.expenseId);
        this.nextActions(res4);
        return;
      }
      let res = await this.submitSettle();
      if (!res.success) {
        return;
      }
      if (res && res.success) {
        this.nextActions(res);
        this["EXPENSE_ID"](res.content.expenseId);
      }
    },
    async queryElementsPromise() {
      let queryElementsParams = {
        db: "smartx_tpm",
        collection: "cfg_rule_node",
        filter: {
          NodeCode: { $in: ["Meal_SubmitSettle"] },
        },
        ContextDataKeys: {
          Version: 3.0,
          MiceId: this.orderDetail.proposalId,
          ItemId: this.orderDetail.itemId,
          curAmount: Number(this.input.originAmount),
          qty: Number(this.input.qty),
        },
      };
      return new Promise((resolve, reject) => {
        miceService
          .queryElements(queryElementsParams)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(false);
          });
      });
    },
    async CheckUserAuth() {
      let params = {
        phoneNumber: SmartStorage.get("authMobile"),
      };
      return new Promise((resolve, reject) => {
        miceService
          .CheckUserAuth(params)
          .then((res) => {
            if (res && res.success) {
              resolve(res);
            } else {
              resolve({});
            }
          })
          .catch((err) => {
            reject(false);
          });
      });
    },
    async GenerateUserAuthUrl() {
      let params = {
        sid: this.mealRules.sid,
        authMobile: SmartStorage.get("authMobile"),
      };
      return new Promise((resolve, reject) => {
        miceService
          .GenerateUserAuthUrl(params)
          .then((res) => {
            if (res && res.success) {
              resolve(res);
            } else {
              resolve({});
            }
          })
          .catch((err) => {
            reject(false);
          });
      });
    },
    async GenerateMemberAuthUrl() {
      let params = {
        sid: this.mealRules.sid,
        authMobile: SmartStorage.get("authMobile"),
      };
      return new Promise((resolve, reject) => {
        miceService
          .GenerateMemberAuthUrl(params)
          .then((res) => {
            if (res && res.success) {
              resolve(res);
            } else {
              resolve({});
            }
          })
          .catch((err) => {
            reject(false);
          });
      });
    },
    SearchShopDetail(params) {
      return new Promise((resolve, reject) => {
        miceService
          .SearchShopDetail(params)
          .then((res) => {
            if (res && res.success) {
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async submitSettle() {
      let params = {
        ...this.input,
        MiceId: this.orderDetail.proposalId,
        gps: this.imygeoLocation,
      };
      return new Promise((resolve, reject) => {
        miceService
          .submitSettle(params)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async nextActions(data) {
      let action = data.content.action;
      switch (action) {
        // 商宴通支付
        case "sytPay":
          this.sytPay();
          break;
        // 口碑支付
        case "kouBeiPay":
          this.kouBeiPay(data);
          break;
        // 闪惠支付
        case "shanhuiPay":
          this.toast("该餐暂不支持");
          break;
        // 支付2.0
        case "centralPay":
          this.$router.push({
            path: "/paySelect/",
          });
          break;
        // 小秘书支付
        case "xiaoMiShuPay":
          this.xiaoMiShuPay(data);
          break;
        // 支付宝
        case "openAliPay":
          this.kouBeiPay(data);
          break;
        // 上传发票
        case "uploadInvoice":
          this.$router.push({ path: "/uploadInvoice" });
          break;
        // 美团餐支付
        case "meiTuanPay":
          this.meiTuanPay(data);
          break;
        // 饿了么支付
        case "gaoDePay":
        case "elePlusPay":
        case "elePay":
        case "zhongZhiPay":
          // if(this.mealRules && this.mealRules.alipay){
          this.$router.push({
            path: "/elePayDetail",
          });
          // }
          // this.elePay(data);
          break;
      }
    },
    sytPay() {
      let params = {
        orderId: this.orderDetail.extOrderId,
        money: this.input.originAmount,
        jobnum: this.memsg.userId,
        name: this.memsg.userName,
        phone: this.memsg.phoneNumber,
      };
      this.openTakeoutPaySYT(params, () => {
        this.$router.push({ path: "/orderList" });
      });
    },
    kouBeiPay(data) {
      let voucherInput = {
        shopId: this.orderDetail.itsItemData.shopId,
        shopName: this.orderDetail.itsItemData.shopName,
        expenseId: data.content.expenseId,
        proposalId: this.orderDetail.proposalId,
        settleAmount: this.input.originAmount,
        specificAmount: data.content.specificAmount,
        qty: this.input.qty,
      };
      this.open(voucherInput);
    },
    xiaoMiShuPay(data) {
      let content = (
        data || {
          isNeedUploadInvoice: false,
          payResultInfo: {},
          shopInfo: {},
        }
      ).content;
      var xmsPayResult = {
        isNeedUploadInvoice: content.isNeedUploadInvoice,
        payResultInfo: content.payResultInfo,
        shopInfo: content.shopInfo,
      };
      this["XMS_PAY_RESULT"](xmsPayResult);
      this.$router.push({
        path: "/xmsPay",
        query: {
          backUrl: "/orderList",
        },
      });
    },
    meiTuanPay(res) {
      if (
        this.event.tenant_code == "roche" &&
        window.__wxjs_environment === "miniprogram"
      ) {
        let params = {
          restaurantId: res.content.shopInfo.restaurantId,
          itemId: this.orderDetail.itemId,
          RestaurantType: 2,
        };
        miceService.meituanLogin(params).then((res) => {
          if (res && res.success) {
            this.$refs.browserLoader.openPop(res.content);
          }
        });
        return;
      }
      let params = {
        restaurantId: res.content.shopInfo.restaurantId,
        itemId: this.orderDetail.itemId,
        RestaurantType: 2,
      };
      this.openMealDianPing(params, () => {
        this.$router.push({ path: "/orderList" });
      });
    },
    elePay(data) {
      let params = {
        itemId: this.orderDetail.itemId,
        totalAmount: this.input.originAmount,
        noDiscountAmount: this.input.originAmount,
        realAmount: this.input.originAmount,
        userId: this.memsg.userId,
        employeePhone: this.memsg.phoneNumber,
        qty: this.input.qty,
      };
      miceService.eleCreate(params).then((res) => {
        if (res && res.success) {
          if (res.code == "313") {
            this.$refs.shoperr.openPop();
          } else {
            if (data.content.action === "gaoDePay") {
              this["ORDER_DETAIL"]({
                ...this.orderDetail,
                orderAmount: this.input.originAmount,
                qty: this.input.qty,
                orderNo: res.content.orderNo,
                isPlusPay: 0,
              });
              this.$router.push({
                path: "/elePayDetail?orderNo=" + res.content.orderNo,
              });
              return;
            }
            let params1 = {
              shopId: this.orderDetail.itsItemData.shopId,
            };
            miceService.getEleType(params1).then((res1) => {
              if (res1 && res1.success) {
                this["ORDER_DETAIL"]({
                  ...this.orderDetail,
                  orderAmount: this.input.originAmount,
                  qty: this.input.qty,
                  orderNo: res.content.orderNo,
                  isPlusPay: res1.content.isBusiness,
                }); // 重新赋值 支付成功页需要用到数据
                this.$router.push({
                  path: "/elePayDetail?orderNo=" + res.content.orderNo,
                });
              }
            });
          }
        }
      });
    },
    back() {
      this.$router.push({ path: "/orderList" });
    },
  },
};
</script>

<style lang="scss">
@import "OrderSettle.scss";
.dialogImg {
  width: 100%;
  padding: .1rem;
  box-sizing: border-box;
  height: auto;
}
</style>